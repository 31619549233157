import { memo } from "react";
import { Box, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Copyright } from "../copyright";
import { FOOTER_LINKS } from "../../config/menu";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
}));

export const Footer = memo(() => {
  return (
    <Box height={200}>
      <Box textAlign="center" pt={2}>
        {FOOTER_LINKS.map((item) => (
          <StyledLink key={item.name} to={item.path}>
            <Button color="inherit">{item.name}</Button>
          </StyledLink>
        ))}
      </Box>
      <Copyright />
    </Box>
  );
});
