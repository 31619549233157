export const ROUTING = {
  Home: {
    path: "/",
    name: "ちいかわ でーたべーす",
    description: "ちいかわ情報をまとめたDB（データベース）サイト",
  },
  About: {
    path: "about",
    name: "サイトについて",
    description:
      "本サイトは2chアンテナサイトです。お気軽にサイトの登録依頼お願いします。",
  },
  NotFound: {
    path: "404",
    name: "404 NOT FOUND",
    description: "お探しのページは見つかりませんでした。",
  },
  Contact: {
    path: "contact",
    name: "お問い合わせ",
    description: "サイトへのお問い合わせはこのページからお願いします。",
  },
  Tweet: {
    path: "tweet",
    name: "ツイート一覧",
    description: "ちいかわのツイートを一覧にまとめました。",
  },
  Episode: {
    List: {
      path: "episode",
      name: "エピソード一覧",
      description: "ちいかわの各エピソードを一覧にまとめました。",
    },
    Detail: {
      path: "episode/:id",
    },
  },
};