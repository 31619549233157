import WebIcon from "@mui/icons-material/Web";
import EmailIcon from "@mui/icons-material/Email";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { ROUTING } from "../routing";
import { toLinkPath } from "../../utils";

export const MENU_LINKS = [
  {
    path: toLinkPath(ROUTING.Tweet.path),
    name: ROUTING.Tweet.name,
  },
  {
    path: toLinkPath(ROUTING.Episode.List.path),
    name: ROUTING.Episode.List.name,
  },
  {
    path: toLinkPath(ROUTING.Contact.path),
    name: ROUTING.Contact.name,
  },
  {
    path: toLinkPath(ROUTING.About.path),
    name: ROUTING.About.name,
  },
];

export const MENU_ICONS = (index: number) => {
  switch (index) {
    case 0:
      return <SpeakerNotesIcon />;
    case 1:
      return <MenuBookIcon />;
    case 2:
      return <EmailIcon />;
    case 3:
      return <WebIcon />;
    default:
      return <WebIcon />;
  }
};

export const FOOTER_LINKS = [
  {
    path: toLinkPath(ROUTING.Contact.path),
    name: ROUTING.Contact.name,
  },
  {
    path: toLinkPath(ROUTING.About.path),
    name: ROUTING.About.name,
  },
];
