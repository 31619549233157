import { ReactNode, memo } from "react";
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  styled,
} from "@mui/material";
import { ScrollRestoration } from "react-router-dom";
import { Header } from "../header";
import { HeadLinePageTitle } from "../headline";
import { GoogleAds } from "../google-ads";
import { useTracking } from "../../hooks/tracking";
import { Footer } from "../footer";

interface Props {
  children?: ReactNode;
  title?: string;
}

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      light: "#ffd4d9",
      main: "#ffc2ca",
      dark: "#daa5ac",
      contrastText: "#000000",
    },
    secondary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

const StyledMain = styled("main")(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    paddingBottom: theme.spacing(6),
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: theme.spacing(8),
  },
}));

export const Template = memo((props: Props) => {
  const { children, title } = props;
  useTracking();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <StyledMain>
        <StyledContainer maxWidth="lg">
          <GoogleAds />
          {title && <HeadLinePageTitle>{title}</HeadLinePageTitle>}
          {children}
        </StyledContainer>
      </StyledMain>
      <Footer />
      <ScrollRestoration />
    </ThemeProvider>
  );
});
