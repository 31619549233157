import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  HomePage,
  AboutPage,
  NotFoundPage,
  EpisodeListPage,
  EpisodeDetailPage,
  ContactPage,
  TweetListPage,
} from "./pages";
import { ROUTING } from "./shared/config/routing";
import { toHtmlPath, toLinkPath } from "./shared/utils";

export const Router = createBrowserRouter([
  {
    path: ROUTING.Home.path,
    element: <HomePage />,
  },
  {
    path: ROUTING.Tweet.path,
    element: <TweetListPage />,
  },
  {
    path: toHtmlPath(ROUTING.Tweet.path),
    element: <Navigate replace to={toLinkPath(ROUTING.Tweet.path)} />,
  },
  {
    path: ROUTING.Episode.List.path,
    element: <EpisodeListPage />,
  },
  {
    path: toHtmlPath(ROUTING.Episode.List.path),
    element: <Navigate replace to={toLinkPath(ROUTING.Episode.List.path)} />,
  },
  {
    path: ROUTING.Episode.Detail.path,
    element: <EpisodeDetailPage />,
  },
  {
    path: toHtmlPath(ROUTING.Episode.Detail.path),
    element: <EpisodeDetailPage />,
  },
  {
    path: ROUTING.Contact.path,
    element: <ContactPage />,
  },
  {
    path: toHtmlPath(ROUTING.Contact.path),
    element: <Navigate replace to={toLinkPath(ROUTING.Contact.path)} />,
  },
  {
    path: ROUTING.About.path,
    element: <AboutPage />,
  },
  {
    path: toHtmlPath(ROUTING.About.path),
    element: <Navigate replace to={toLinkPath(ROUTING.About.path)} />,
  },
  {
    path: ROUTING.NotFound.path,
    element: <NotFoundPage />,
  },
  {
    path: toHtmlPath(ROUTING.NotFound.path),
    element: <Navigate replace to={toLinkPath(ROUTING.NotFound.path)} />,
  },
  {
    path: "*",
    element: <Navigate replace to={toLinkPath(ROUTING.NotFound.path)} />,
  },
]);
