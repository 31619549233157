import { SITE_NAME } from "../config";

/**
 * bool値を数値に変換
 * @param {boolean} value - bool値
 * @returns {boolean} true: 1, false: 0
 */
export const toFlgZeroOrOne = (value: boolean) => {
  switch (value) {
    case true:
      return 1;
    case false:
      return 0;
  }
};

export const toHtmlPath = (value: string) => {
  return `${value}/index.html`;
};

export const toLinkPath = (value: string) => {
  return `/${value}/`;
};

/**
 * 配列同士の重複データをチェック
 * @param {any[]} x - 配列 x
 * @param {any[]} y - 配列 y
 * @returns {boolean} 重複データがある場合はtrueを返す
 */
export const checkIsDuplicate = (x?: any[], y?: any[]): boolean => {
  const a = x ?? [];
  const b = y ?? [];
  return [...a, ...b].filter((i) => a.includes(i) && b.includes(i)).length > 0;
};

export const createHtmlTitle = (value: string) => {
  return `${value} - ${SITE_NAME}`;
}