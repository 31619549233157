import { memo } from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GADS_PUB_ID, GADS_SLOT_ID } from "../../config";

const StyledDevBlock = styled("div")(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.light,
  textAlign: "center",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const GoogleAds = memo(() => {
  return (
    <Grid alignItems="center">
      {process.env.NODE_ENV !== "development" ? (
        <ins
          className="adsbygoogle"
          style={{ display: "block", textAlign: "center" }}
          data-ad-client={GADS_PUB_ID}
          data-ad-slot={GADS_SLOT_ID}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      ) : (
        <StyledDevBlock>Google Ads</StyledDevBlock>
      )}
    </Grid>
  );
});
