import { ContactGeneralDto } from "../../models/contact";
import {
  API_PATH_SEND_MAIL_GENERAL,
  DATA_PATH_EPISODES,
  DATA_PATH_FILTER,
  DATA_PATH_TWEET,
  DATA_PATH_VERSION,
  DATA_PATH_LINKS_TAG,
} from "../../shared/config";
import { axios } from "../../shared/lib/axios";

export class FetchService {
  async findTweetList(): Promise<any> {
    const response = await fetch(DATA_PATH_TWEET);
    console.info(
      "[INFO][FetchService] findTweetList done. response=",
      response
    );
    return response.json();
  }

  async findEpisodesList(): Promise<any> {
    const response = await fetch(DATA_PATH_EPISODES);
    console.info(
      "[INFO][FetchService] findEpisodesList done. response=",
      response
    );
    return response.json();
  }

  async findVersionList(): Promise<any> {
    const response = await fetch(DATA_PATH_VERSION);
    console.info(
      "[INFO][FetchService] findVersionList done. response=",
      response
    );
    return response.json();
  }

  async findFilterList(): Promise<any> {
    const response = await fetch(DATA_PATH_FILTER);
    console.info(
      "[INFO][FetchService] findFilterList done. response=",
      response
    );
    return response.json();
  }

  async findTagLinksList(): Promise<any> {
    const response = await fetch(DATA_PATH_LINKS_TAG);
    console.info(
      "[INFO][FetchService] findTagLinksList done. response=",
      response
    );
    return response.json();
  }

  async sendMailContactGeneral(
    data: ContactGeneralDto | undefined
  ): Promise<any> {
    const response = await axios.post(API_PATH_SEND_MAIL_GENERAL, data);
    console.info(
      "[INFO][FetchService] sendMailContactGeneral done. response=",
      response
    );
    return response;
  }
}
