import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Router } from "./App.router";
import { Loader } from "./shared/components";
import { QUERY_KEY_CARD } from "./shared/config";
import { queryClient } from "./services";
import "./App.css";


const App = () => {
  queryClient.setQueryDefaults(QUERY_KEY_CARD, { cacheTime: 1000 * 60 * 60 })
  return (
    <Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={Router} />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
