import { MouseEventHandler, ReactNode, memo } from "react";
import { Button, styled } from "@mui/material";
import {
  COLOR_BTN_SUBMIT,
  COLOR_BTN_SUBMIT_HOVER,
  COLOR_BTN_TEXT,
} from "../../config";

const StyledBtn = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: COLOR_BTN_SUBMIT_HOVER,
  },
}));

interface Props {
  children?: ReactNode;
  startIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ActionBtn = memo((props: Props) => {
  const { children, startIcon, onClick } = props;
  return (
    <StyledBtn
      variant="contained"
      size="large"
      type="submit"
      // [info] my (margin y): 上下マージン
      sx={{
        my: 2,
        backgroundColor: COLOR_BTN_SUBMIT,
        color: COLOR_BTN_TEXT,
        fontWeight: "bold",
      }}
      startIcon={startIcon}
      onClick={onClick}
    >
      {children}
    </StyledBtn>
  );
});
