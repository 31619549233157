export const ROOT_URL = process.env.REACT_APP_ROOT_URL as string;
export const GA4_ID = process.env.REACT_APP_GA4_ID as string;
export const GTM_ID = process.env.REACT_APP_GTM_ID as string;
export const GADS_PUB_ID = `ca-pub-${process.env.REACT_APP_GOOGLE_ADS_PUB_ID}`;
export const GADS_SLOT_ID = process.env.REACT_APP_GOOGLE_ADS_SLOT_ID as string;
export const DEFAULT_LIMIT_PARAM = 30;
export const DEFAULT_OFFSET_PARAM = 0;
export const DEFAULT_PAGE_PARAM = 1;
export const SCREEN_SIZE_VISIBLE_XS = { display: { sm: "block", md: "none" } };
export const SCREEN_SIZE_HIDDEN_XS = { display: { sm: "none", md: "block" } };
export const SCREEN_SIZE_HIDDEN_XS_FLEX = {
  display: { xs: "none", sm: "flex" },
};
export const DATA_PATH_TWEET = "/data/tweet.json";
export const DATA_PATH_EPISODES = "/data/episodes.json";
export const DATA_PATH_VERSION = "/data/version.json";
export const DATA_PATH_FILTER = "/data/filter.json";
export const DATA_PATH_LINKS_TAG = process.env.REACT_APP_ROOT_LINKS_TAG_URL as string;
export const API_PATH_SEND_MAIL_GENERAL = "/api/send/general";
export const QUERY_KEY_TWEET = "tweet";
export const QUERY_KEY_EPISODES = "episodes";
export const QUERY_KEY_VERSION = "version";
export const QUERY_KEY_FILTER = "filter";
export const QUERY_KEY_LINKS_TAG = "linksTag";
export const QUERY_KEY_CARD = "card";
export const QUERY_KEY_API_SEND_MAIL_GENERAL = "general";
export const MATOME_MANGA_URL = "https://manga.itsys-tech.com";
export const TWITTER_URL_ACCOUNT = "https://twitter.com/ngnchiikawa/status/";
export const TWITTER_URL_IMG = {
  root: "https://pbs.twimg.com/media/",
  param_format: "?format=jpg",
  param_size_s: "&name=small",
  param_size_thumbnail: "&name=240x240",
};
export const TWITTER_URL_MOVIE = {
  root: "https://video.twimg.com/tweet_video/",
  extension: ".mp4",
};
export const TWITTER_OFFICIAL_ACCOUNTS = [
  {
    url: "https://twitter.com/ngnchiikawa",
    img: "https://pbs.twimg.com/profile_images/1210137605234278400/IAozvMHj_bigger.jpg",
    display_name: "ちいかわ💫アニメ火金",
    account_name: "@ngnchiikawa",
  },
  {
    url: "https://twitter.com/ngntrtr",
    img: "https://pbs.twimg.com/profile_images/876996186959302656/PhOiPcHR_bigger.jpg",
    display_name: "ナガノ",
    account_name: "@ngntrtr",
  },
  {
    url: "https://twitter.com/anime_chiikawa",
    img: "https://pbs.twimg.com/profile_images/1653907933170905088/MeFsJx61_bigger.jpg",
    display_name: "『ちいかわ』アニメ公式",
    account_name: "@anime_chiikawa",
  },
  {
    url: "https://twitter.com/chiikawa_market/",
    img: "https://pbs.twimg.com/profile_images/1572805340596703234/LQCy_Q3Q_bigger.png",
    display_name: "ちいかわマーケット公式",
    account_name: "@chiikawa_market",
  },
];
export const SITE_NAME = "ちいかわ でーたべーす";
export const TAG_DESCRIPTION_DEFAULT =
  "ちいかわ情報をまとめたDB（データベース）サイト";
export const TAG_OG_TYPE_TOP = "website";
export const TAG_OG_TYPE_CONTENT = "article";
export const TAG_OG_IMG_DEFAULT = `${ROOT_URL}/site-image.png`;
export const TAG_KEYWORDS_DEFAULT = ["ちいかわ"];
export const COLOR_BTN_SUBMIT = "#202020";
export const COLOR_BTN_SUBMIT_HOVER = "#525252";
export const COLOR_BTN_TEXT = "#ffffff";
export const FILTER_LABEL_ALL = {
  id: "all",
  name: "すべて",
};
export const FILTER_ITEMS = ["カテゴリー", "キャラクター"];