import { ReactNode, memo } from "react";
import { styled } from "@mui/material";

const StyledH3 = styled("h3")(({ theme }) => ({
  position: "relative",
  color: theme.palette.primary.contrastText,
  padding: "4px 0px 4px 40px",
  backgroundColor: theme.palette.primary.light,
  borderRadius: 40,
  "&::after": {
    position: "absolute",
    transform: "translateY(-50%)",
    content: "''",
    backgroundColor: theme.palette.primary.contrastText,
    width: 13,
    height: 13,
    left: 15,
    top: "50%",
    borderRadius: 10,
  },
}));

const StyledH4 = styled("h4")(({ theme }) => ({
  padding: 1,
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.primary.light,
  color: theme.palette.text.primary,
}));

const StyledTop = styled("h2")(({ theme }) => ({
  fontSize: "18px",
  position: "relative",
  padding: "0 65px",
  textAlign: "center",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "30px",
  "&::before": {
    position: "absolute",
    top: "calc(50% - 1px)",
    left: 0,
    width: "100%",
    height: "2px",
    content: "''",
    backgroundColor: theme.palette.primary.main,
  },
  "& span": {
    position: "relative",
    padding: "0 1em",
    content: "''",
    backgroundColor: "#ffffff",
  },
}));

const StyledPageTitle = styled("h2")(({ theme }) => ({
  margin: "0 10px 40px 10px",
  position: "relative",
  borderBottom: "2px solid",
  borderColor: theme.palette.primary.contrastText,
  color: theme.palette.primary.contrastText,
  textAlign: "center",
  fontSize: "clamp(19px, 3vw, 22px)",
  "&::after": {
    border: "15px solid transparent",
    borderTopColor: theme.palette.primary.contrastText,
    borderBottomWidth: 0,
    content: "''",
    top: "100%",
    left: "50%",
    position: "absolute",
    width: 0,
    height: 0,
    bottom: 0,
    margin: "0 0 0 -15px",
  },
}));

interface Props {
  children?: ReactNode;
}

export const HeadLine3 = memo((props: Props) => {
  const { children } = props;

  return <StyledH3>{children}</StyledH3>;
});

export const HeadLine4 = memo((props: Props) => {
  const { children } = props;

  return <StyledH4>{children}</StyledH4>;
});

export const HeadLineTop = memo((props: Props) => {
  const { children } = props;

  return (
    <StyledTop>
      <span>{children}</span>
    </StyledTop>
  );
});

export const HeadLinePageTitle = memo((props: Props) => {
  const { children } = props;

  return (
    <StyledPageTitle>
      <span>{children}</span>
    </StyledPageTitle>
  );
});