import { memo } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import TwitterIcon from "@mui/icons-material/Twitter";
import { ImgCardIF } from "../../../models";

interface Props extends ImgCardIF {
  index?: number;
  onClickImg: (index?: number, imgNumber?: number) => void;
}

export const ImgCard = memo((props: Props) => {
  const { updated, url, imgUrl, movieUrl, quote, index, onClickImg } = props;

  return (
    <Card sx={{ width: 320, height: 570, margin: "auto" }}>
      <Grid container direction="column">
        {/* [INFO] CardActionArea内にGridを配置するとGridの”space-between”がきかなくなる */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid sx={{ padding: 1 }}>
            <IconButton
              size="small"
              color="primary"
              href={url}
              target="_blank"
              rel="noopener"
            >
              <TwitterIcon />
            </IconButton>
          </Grid>
          <Grid sx={{ padding: "8px 14px 8px 8px" }}>
            <Typography variant="body2" color="text.secondary" fontSize="10px">
              {updated}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <CardActionArea onClick={() => onClickImg(index, imgUrl?.length)}>
            {imgUrl?.length === 1 ? (
              // [INFO] 画像データ = 1
              <Box
                sx={{ height: 450, width: 320, display: "flex" }}
                justifyContent="center"
              >
                {imgUrl.map((path, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={path}
                    alt={path}
                    sx={{
                      maxHeight: 450,
                      margin: "auto",
                    }}
                  />
                ))}
              </Box>
            ) : imgUrl ? (
              /// [INFO] 画像データ > 1
              <Box sx={{ height: 450, width: 320 }} justifyContent="center">
                {imgUrl.map((path, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={path}
                    alt={path}
                    sx={{
                      maxHeight: 450 / imgUrl.length,
                      margin: "auto",
                    }}
                  />
                ))}
              </Box>
            ) : movieUrl ? (
              // [INFO] 動画データ
              <Box
                sx={{ height: 450, width: 320, display: "flex" }}
                justifyContent="center"
              >
                <CardMedia
                  component="video"
                  image={movieUrl}
                  autoPlay
                  muted
                  playsInline
                  loop
                />
              </Box>
            ) : (
              // [INFO] データなし
              <Box
                sx={{ height: 450, width: 320, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ margin: "auto" }}>Not Found</Box>
              </Box>
            )}
            {/* [INFO] 引用エリア */}
            {quote.length > 3 ? (
              // [INFO] 引用数 > 3 ： 上下パディング 0
              <CardContent sx={{ px: 1.5, py: 0 }}>
                <Box
                  color="text.secondary"
                  fontSize="10px"
                  sx={{ wordBreak: "break-all" }}
                >
                  <FormatQuoteIcon
                    sx={{ transform: "scale(-1, -1)", fontSize: "12px" }}
                  />
                  引用元：
                  <Box key={0} pl={0}>
                    ・{quote[0]}
                  </Box>
                  <Box key={1} pl={0}>
                    ・{quote[1]}
                  </Box>
                  <Box key={2} pl={0}>
                    ・{quote[2]}…
                  </Box>
                  <Box textAlign="right" mt={-1}>
                    <FormatQuoteIcon sx={{ fontSize: "12px" }} />
                  </Box>
                </Box>
              </CardContent>
            ) : quote.length === 3 ? (
              // [INFO] 引用数 = 3 ： 上下パディング 0
              <CardContent sx={{ px: 1.5, py: 0 }}>
                <Box
                  color="text.secondary"
                  fontSize="10px"
                  sx={{ wordBreak: "break-all" }}
                >
                  <FormatQuoteIcon
                    sx={{ transform: "scale(-1, -1)", fontSize: "12px" }}
                  />
                  引用元：
                  {quote.map((str, index) => (
                    <Box key={index} pl={0}>
                      ・{str}
                    </Box>
                  ))}
                  <Box textAlign="right" mt={-1}>
                    <FormatQuoteIcon sx={{ fontSize: "12px" }} />
                  </Box>
                </Box>
              </CardContent>
            ) : (
              // [INFO] 引用数 <= 2 ： 上下パディング あり
              <CardContent sx={{ px: 1.5 }}>
                <Box
                  color="text.secondary"
                  fontSize="10px"
                  sx={{ wordBreak: "break-all" }}
                >
                  <FormatQuoteIcon
                    sx={{ transform: "scale(-1, -1)", fontSize: "12px" }}
                  />
                  引用元：
                  {quote.map((str, index) => (
                    <Box key={index} pl={0}>
                      ・{str}
                    </Box>
                  ))}
                  <Box textAlign="right" mt={-0.5}>
                    <FormatQuoteIcon sx={{ fontSize: "12px" }} />
                  </Box>
                </Box>
              </CardContent>
            )}
          </CardActionArea>
        </Grid>
      </Grid>
    </Card>
  );
});
