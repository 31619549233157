import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { GTM_ID } from "../../config";

export const useTracking = () => {
  const location = useLocation();

  // [INFO] ページ遷移時にGTMで測定
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
    // [INFO] react-helmetで設定したtitleタグ情報はレンダリング直後には反映されない
    //        そのためsetTimeoutを使用してhelmetの処理終了を待つ
    window.setTimeout(() => {
      console.log(
        `[INFO][useTracking] page=${location.pathname}${location.search}, title=${document.title}`
      );
      // [INFO] カスタムイベントを設定する場合は以下のdataLayerに値を設定する
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: "pageview",
      //     page: {
      //       url: `${location.pathname}${location.search}`,
      //       title: document.title,
      //     },
      //   },
      // });
    });
  }, [location]);
};
