import { memo } from "react";
import { Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { SITE_NAME } from "../../config";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: "0.7rem",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
}));

export const Copyright = memo(() => {
  return (
    <StyledTypography align="center" pt={1}>
      {"Copyright © "}
      <StyledLink color="inherit" to="/">
        {SITE_NAME}
      </StyledLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </StyledTypography>
  );
});
