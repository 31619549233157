import { ChangeEvent, memo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckedItem } from "../../../models";
import { FILTER_LABEL_ALL } from "../../config";

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} defaultExpanded={true} {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string, name: string) => void;
  id: string;
  title: string;
  isCheckedAll: boolean;
  isIndeterminate: boolean;
  items: CheckedItem[];
}

export const FilterCheckbox = memo((props: Props) => {
  const { onChange, isCheckedAll, isIndeterminate, id, title, items } = props;

  return (
    <StyledAccordion>
      <StyledAccordionSummary>
        <Typography>{title}</Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <FormControlLabel
          label={FILTER_LABEL_ALL.name}
          control={
            <Checkbox
              checked={isCheckedAll}
              indeterminate={isIndeterminate}
              onChange={(event) => onChange(event, id, FILTER_LABEL_ALL.name)}
            />
          }
          sx={{ ml: 1 }}
        />
        <Box sx={{ ml: 4 }}>
          {items?.map((item, index) => (
            <FormControlLabel
              key={index}
              label={item.name}
              control={
                <Checkbox
                  checked={item.checked}
                  onChange={(event) => onChange(event, id, item.name)}
                />
              }
            />
          ))}
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
});
