import { memo } from "react";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/material";

interface Props {
  isOpen: boolean;
  images: ImagesListType;
  imgIndex: number;
  onClose?: () => void;
  onPrev: () => void;
  onNext: () => void;
}

export const ImgGallery = memo((props: Props) => {
  const { isOpen, images, imgIndex, onClose, onPrev, onNext } = props;

  return (
    <Lightbox
      isOpen={isOpen}
      onPrev={onPrev}
      onNext={onNext}
      images={images}
      currentIndex={imgIndex}
      singleClickToZoom
      renderHeader={() => (
        <Stack direction="row" justifyContent="flex-end" onClick={onClose}>
          <CloseIcon sx={{ fontSize: "50px", color: "#ffffff" }} />
        </Stack>
      )}
      renderFooter={() => (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            fontSize: "30px",
            color: "#ffffff",
            position: "relative",
            bottom: 15,
          }}
        >
          {imgIndex === 0 ? (
            <ArrowBackIosIcon sx={{ color: "#000000" }} />
          ) : (
            <ArrowBackIosIcon onClick={onPrev} />
          )}
          <Box px={1}>
            {imgIndex + 1} / {images.length}
          </Box>
          {imgIndex === images.length - 1 ? (
            <ArrowForwardIosIcon sx={{ color: "#000000" }} />
          ) : (
            <ArrowForwardIosIcon onClick={onNext} />
          )}
        </Stack>
      )}
      className="cool-class"
      style={{ background: "rgba(0, 0, 0, 0.8)", zIndex: 10000 }}
      onClose={onClose}
    />
  );
});
